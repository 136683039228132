"use client";

// External
import { useContext, useEffect } from "react";

// Internal
import { CameraContext } from "@/contexts/CameraContext";

const NotFound = () => {
  const { setIs404 } = useContext(CameraContext);

  useEffect(() => {
    setIs404(true);
  }, [setIs404]);

  return <span className="sr-only">404</span>;
};

export default NotFound;
